@import '../../../styles/_variables.scss';

.btn {
    color: white;
    border-radius: 8px;
    border: 1px solid transparent;
    font-weight: 600;
}

.btn-primary {
    background-color: $dark-purple;
    &:hover {
        border-color: white;
    }
    &:focus {
        color: $dark-purple;
        background-color: white;
    }
    
}

.btn-secondary {
    background-color: transparent;
    border: 1px solid $blue;
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: none;
        color: $dark-purple;
        color: $blue;;
    }
}