// Creative Studios Color Pallete

$turqoise: #00f4ff;
$fucsia: #ff3ff6;
$blue: #01b0e7;
$deep-ocean-blue: #2e2a7b;
$dark-blue: #000d3e;

// Creative Studios Secondary Pallete

$secondary-turqoise: #01fffa;
$purple: #d073ff;
$dark-purple: #5219ae;
$red: #ff081d;
$salmon: #ff5a78;


// Examples for future use

$background-color: #f06292;
$text-color: #f1d3b3;
$btn-width: 120px;
$btn-height: 40px;
$block-padding: 60px;

