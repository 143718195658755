@tailwind base;
@tailwind components;
@tailwind utilities;


.painting {
  text-align: center;
  margin: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-300);
}

.painting h4 {
  margin-bottom: 0;
}

.painting p {
  margin-top: 0;
}

.painting img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.river {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-direction: column;
  margin-top: 3rem;
}

.river article {
  width: 100%;
}

.river article button:first-child {
  margin-right: var(--spacing-m);
}

.river > img {
  width: 100%;
  max-width: 450px;
}

.tags {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.tags label {
  cursor: pointer;
}

@media screen and (min-width: 40em) {
  .river:nth-child(even) {
    flex-direction: row-reverse;
  }

  .river:nth-child(odd) {
    flex-direction: row;
  }

  .river article,
  .river > img {
    width: 50%;
  }
}


$animationSpeed: 40s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

// Styling for logo company slider
.slider {
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
	
	&::before,
	&::after {
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
    display: flex;
    height: 100px;
    align-items: center;
	}

  .slide-track-2 {
    animation-direction: reverse;
  }
	
	.slide {
		width: auto;
    padding-inline: 1rem;
	}
}


/* For Tablet and Desktop View */
@media screen and (min-device-width: 768px) {
  .main-container {
    padding-inline: 7rem;
  }
}